<!-- eslint-disable -->
<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="light-blue">
                        <v-toolbar-title>
                          <v-avatar>
                          <img
                            src="../../assets/inprem_favicon_circle.png"
                          class="align-self-center"
                          />
                          </v-avatar>
                          Login form
                        </v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              prepend-icon="mdi-account-box"
                              name="login"
                              v-model="t_login"
                              label="Login"
                              type="text"
                              height=40
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              v-model="t_password"
                              label="Password"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light-blue" @click="dologin" >Login</v-btn>
                        <v-btn color="light-blue" @click="cancellogin">Cancel</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
/* eslint-disable */
import CrmService from '../../services/CrmService'
import { mapState, mapMutations } from 'vuex'
//************************
// JAVASCRIPT
//************************
export default {
   name: 'Login',
   props: {
      source: String,
   },
//************************
// DATA
//************************
data () { return {
  t_login: "sarogers",
  t_password: "Flamingo123$",
  


  }
},
//************************
// METHODS
//************************
methods: {

async dologin() {
   const login = this.t_login
   const password = this.t_password
   const userobj = await CrmService.userLogin(login, password);
   if (userobj == null) {
      alert("Login name not found.")
      return
   }
   if (userobj == -1) {
      alert("Incorrect password.")
      return
   }
   // Login is correct - set up the state
   this.$store.state.loggedin.state = true
   this.$store.state.loggedin.userUid = userobj.userUid
   this.$store.state.loggedin.firstName = userobj.firstName
   this.$store.state.loggedin.lastName = userobj.lastName
   
   this.$store.state.current_customer.customerUid = userobj.customerUid
   this.$store.state.current_customer.organizationName = userobj.organizationName

   this.$store.state.current_site.uid = userobj.siteUid
   this.$store.state.current_site.name = userobj.siteName
   
   this.$router.push('/sitehome')
   // Set the Customer info here
    


  },

  cancellogin() {

  }

},

} // Export default end bracket

</script>

<style>
.v-label.v-label, .v-alert.v-alert {
    font-size: 18px;}
.v-toolbar__title{
  font-size: 1.6rem;
}
    </style>